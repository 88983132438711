// ============ [START] Reference: https://piccalil.li/blog/a-modern-css-reset/ ============
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0ms !important;
    animation-iteration-count: 0 !important;
    transition-duration: 0ms !important;
    scroll-behavior: auto !important;
  }
}
tr,
th,
td {
  padding: 0;
  font: inherit;
}
// ============ [END] Reference: https://piccalil.li/blog/a-modern-css-reset/ ============

address {
  font-style: inherit;
}

button {
  user-select: none;
}

input,
textarea,
button,
select,
a,
[tabindex],
[draggable] {
  -webkit-tap-highlight-color: transparent;
}

input {
  display: block;
  width: 100%;
  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &::-webkit-file-upload-button {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
}

select {
  cursor: pointer;
}
:is(input, button, a, select, textarea, [contenteditable], [tabindex]):focus {
  outline: none;
}

input,
button,
select,
textarea {
  background-color: transparent;
  letter-spacing: inherit;
}

textarea {
  resize: none;
}

:is(select, button, textarea, input):disabled {
  cursor: not-allowed;
}
