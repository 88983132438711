/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
@tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;

/**
  * Use this directive to control where Tailwind injects the hover, focus,
  * responsive, dark mode, and other variants of each class.
  *
  * If omitted, Tailwind will append these classes to the very end of
  * your stylesheet by default.
  */
@tailwind variants;

html {
  height: 100%;
}

body {
  @apply font-korea;
  height: 100%;
}

#root {
  @apply min-h-[100vh] flex flex-col;
  height: 100%;
}

#toast-portal {
  z-index: 9999;
  position: relative;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  @apply text-text-dark;
}

.btn-dark {
  @apply bg-bg-dark flex items-center rounded-full px-3 hover:bg-bg-darker active:bg-bg-darkest;
}

.btn-primary {
  @apply flex items-center justify-center text-center flex-shrink-0 bg-primary hover:bg-primary-dark active:bg-primary-darker text-bg-contrast font-bold whitespace-nowrap rounded-lg transition-all duration-300;
}

.btn-primary[disabled] {
  @apply bg-bg-gray-darker text-text-gray;
}

.btn-primary-outlined {
  @apply flex items-center justify-center text-center whitespace-nowrap flex-shrink-0 border border-primary hover:border-primary-dark hover:bg-bg-gray active:border-primary-darker active:bg-gray-200 active:bg-opacity-100 text-primary rounded px-[20px] text-caption2 font-bold;
}

.btn-primary-outlined[disabled] {
  @apply border-border-gray-lighter text-text-gray;
}

.btn-secondary {
  @apply flex items-center justify-center text-center flex-shrink-0 bg-secondary hover:bg-secondary-dark active:bg-secondary-darker text-bg-contrast rounded-lg font-bold whitespace-nowrap;
}

.btn-secondary-outlined {
  @apply flex items-center justify-center text-center whitespace-nowrap flex-shrink-0 border border-secondary hover:border-secondary-dark hover:bg-bg-gray active:border-secondary-darker active:bg-gray-200 active:bg-opacity-100 text-secondary rounded px-[20px] font-bold;
}

.btn-secondary-outlined[disabled] {
  @apply border-border-gray-lighter text-text-gray;
}

.btn-gray {
  @apply flex items-center justify-center text-center whitespace-nowrap bg-gray-ea text-text-dark rounded font-bold flex-shrink-0 hover:bg-bg-gray-darker;
}

/* Datepicker */
.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__input-container input {
  @apply block bg-white;
}

.react-datepicker-popper {
  @apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded h-fit;
}

.react-datepicker__time-container {
  @apply flex flex-col justify-center w-24;
}

.react-datepicker__header--time {
  @apply text-base text-center text-gray-800 rounded p-1 pt-2;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.react-timepicker {
  @apply p-0 w-[96px] box-content !important;
}

.react-datepicker__time-list {
  @apply no-scrollbar overflow-y-auto w-full h-40;
}

.react-datepicker__time-list-item--selected {
  @apply bg-primary text-bg-contrast hover:bg-opacity-100 !important;
}

.react-datepicker__time-list-item {
  @apply hover:bg-primary hover:bg-opacity-10 p-2;
}

.react-datepicker-time__header {
  @apply text-base font-semibold text-text-dark;
}
.react-datepicker-left {
  @apply absolute left-0 right-auto top-12 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-12 transform-none !important;
}

.react-datepicker-bottom {
  @apply absolute bottom-12 top-auto !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-text-dark rounded hover:bg-primary hover:bg-opacity-10 cursor-pointer;
}

.react-datepicker__day--disabled {
  @apply opacity-40 hover:bg-transparent cursor-not-allowed !important;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-primary;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-primary;
}

.react-datepicker__day--selected {
  @apply bg-primary text-white;
}

.react-datepicker__day--range-start {
  @apply bg-primary text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-primary text-white hover:text-gray-700 hover:bg-white;
}

/* Jarry */
.h-88 {
  height: 88px;
}

.border-none {
  border: 0;
}

.position-absolute {
  position: absolute;
  background-color: white;
  z-index: 1000;
  padding-bottom: 2rem;
  padding-top: 2rem;
  width: calc(100% - 80px);
}

.text-onda {
  color: #004FC5;
}

/* Radio */

[type='radio'] {
  @apply appearance-none relative border-2 border-text-gray rounded-full w-5 h-5 after:w-0 after:h-0 after:transition-all after:duration-75 checked:after:w-[10px] checked:after:h-[10px] checked:border-primary checked:after:absolute checked:after:left-0 checked:after:top-0 after:bg-primary checked:after:right-0 checked:after:bottom-0 checked:after:m-auto checked:after:rounded-full focus:outline-none transition-all duration-300 hover:border-text-gray-dark;
}

/* CheckBox */

[type='checkbox'] {
  @apply appearance-none relative border-2 border-text-gray w-5 h-5 checked:bg-primary checked:border-primary after:border-white after:transition-all after:border-b-0 after:border-r-0 after:duration-75  after:w-[6px] after:h-[12px] after:absolute after:left-0 after:top-[-3px] checked:after:border-b-[2px] checked:after:border-r-[2px] after:rotate-45 after:right-0 after:bottom-0 after:m-auto focus:outline-none transition-all duration-300 hover:border-text-gray-dark checked:hover:border-primary;
}

[type='checkbox']:disabled {
  @apply border-border-gray-lighter bg-gray-500;
}
