:root {
  --app-content-indent: 25px;
  --app-content-width: 1440px;
  --app-header-spacing: 70px;
  --dashboard-footer-spacing: 43px;
}
@media (max-width: 768px) {
  :root {
    --app-content-indent: 15px;
    --app-header-spacing: 48px;
  }
}
